@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts";

body {
  font-family: "Poppins", sans-serif;
}

input:focus {
  outline: none !important;
}
/* custom scrollbar */
*::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}
*::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 0.313rem;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
*::-webkit-scrollbar-track {
  // background: #eeeeee;
}
button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins", sans-serif;
}

// For Tree select dropdown
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #f5f3f0 !important;
  color: #585550 !important;
}
.p-panel .custom-panel {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 5px 0px rgba(0, 0, 0, 0.05);
  top: 92%;
  overflow: auto;
  border-radius: 0 0 8px 8px;
  max-height: calc(100vh - 185px);
}
.p-panel .custom-panel .p-panel-content {
  background: rgb(255, 255, 251);
  border: none;
}
.svc-question__content .sd-row__question {
  white-space: pre-wrap;
}

.sd-row__question {
  white-space: pre-wrap !important;
}

// For survey creator widget some setting options hide
.spg-row.spg-clearfix > div[data-key="mode0"],
.spg-row.spg-clearfix > div[data-key="cookieName0"],
.spg-row.spg-clearfix > div[data-key="widthMode0"],
.spg-row.spg-clearfix > div[data-key="width0"],
.spg-row.spg-clearfix > div[data-key="logo0"],
.spg-row.spg-clearfix > div[data-key="navigation0"],
.spg-row.spg-clearfix > div[data-key="question0"],
.spg-row.spg-clearfix > div[data-key="logic0"],
.spg-row.spg-clearfix > div[data-key="data0"],
.spg-row.spg-clearfix > div[data-key="validation0"],
.spg-row.spg-clearfix > div[data-key="showOnCompleted0"],
.spg-row.spg-clearfix > div[data-key="timer0"],
.spg-row.spg-clearfix > div[data-key="layout0"],
.spg-row.spg-clearfix > div[data-key="choicesByUrl0"],
.spg-row.spg-clearfix > div[data-key="locale0"],
.spg-row.spg-clearfix > div[data-key="visible0"],
.spg-row.spg-clearfix > div[data-key="showTitle0"],
.spg-row.spg-clearfix > div[data-key="isRequired0"],
.spg-row.spg-clearfix > div[data-key="readOnly0"],
.spg-row.spg-clearfix > div[data-key="showCommentArea0"],
.spg-row.spg-clearfix > div[data-key="closeOnSelect0"],
.spg-row.spg-clearfix > div[data-key="maxSelectedChoices0"],
.spg-row.spg-clearfix > div[data-key="searchEnabled0"],
.spg-row.spg-clearfix > div[data-key="hideSelectedItems0"],
.spg-row.spg-clearfix > div[data-key="allowClear0"],
.spg-row.spg-clearfix > div[data-key="placeholder0"],
.spg-row.spg-clearfix > div[data-key="showPageTitles0"],
.spg-row.spg-clearfix > div[data-key="showPageNumbers0"],
.spg-row.spg-clearfix > div[data-key="choicesFromQuestion0"],
.spg-row.spg-clearfix > div[data-key="showOtherItem0"],
.spg-row.spg-clearfix > div[data-key="showClearButton0"],
.spg-row.spg-clearfix > div[data-key="separateSpecialChoices0"],
.spg-row.spg-clearfix > div[data-key="rateStep0"],
.spg-row.spg-clearfix > div[data-key="showNoneItem0"],
.spg-row.spg-clearfix > div[data-key="showSelectAllItem0"],
.spg-row.spg-clearfix > div[data-key="choicesOrder0"],
.spg-row.spg-clearfix > div[data-key="Image value0"],
.spg-row.spg-clearfix > div[data-key="QuestionImage0"],
.spg-row.spg-clearfix > div[data-key="autocomplete0"],
.spg-row.spg-clearfix > div[data-key="dataList0"],
.spg-row.spg-clearfix > div[data-key="name0"],
.sd-title.sd-container-modern__title .svc-logo-image,
.svc-question__content.svc-question__content--tagbox.svc-question__content--selected
  > div.sd-element.sd-question.sd-row__question.sd-row__question--small
  > div.sd-question__content {
  display: none;
}

.spg-panel__content .spg-row:has([data-key="mode0"]),
.spg-panel__content .spg-row:has([data-key="cookieName0"]),
.spg-panel__content .spg-row:has([data-key="widthMode0"]),
.spg-panel__content .spg-row:has([data-key="width0"]),
.spg-panel__content .spg-row:has([data-key="logo0"]),
.spg-panel__content .spg-row:has([data-key="navigation0"]),
.spg-panel__content .spg-row:has([data-key="question0"]),
.spg-panel__content .spg-row:has([data-key="logic0"]),
.spg-panel__content .spg-row:has([data-key="data0"]),
.spg-panel__content .spg-row:has([data-key="validation0"]),
.spg-panel__content .spg-row:has([data-key="showOnCompleted0"]),
.spg-panel__content .spg-row:has([data-key="timer0"]),
.spg-panel__content .spg-row:has([data-key="layout0"]),
.spg-panel__content .spg-row:has([data-key="choicesByUrl0"]),
.spg-panel__content .spg-row:has([data-key="locale0"]),
.spg-panel__content .spg-row:has([data-key="visible0"]),
.spg-panel__content .spg-row:has([data-key="showTitle0"]),
.spg-panel__content .spg-row:has([data-key="isRequired0"]),
.spg-panel__content .spg-row:has([data-key="readOnly0"]),
.spg-panel__content .spg-row:has([data-key="showCommentArea0"]),
.spg-panel__content .spg-row:has([data-key="closeOnSelect0"]),
.spg-panel__content .spg-row:has([data-key="maxSelectedChoices0"]),
.spg-panel__content .spg-row:has([data-key="searchEnabled0"]),
.spg-panel__content .spg-row:has([data-key="hideSelectedItems0"]),
.spg-panel__content .spg-row:has([data-key="allowClear0"]),
.spg-panel__content .spg-row:has([data-key="placeholder0"]),
.spg-panel__content .spg-row:has([data-key="showPageTitles0"]),
.spg-panel__content .spg-row:has([data-key="showPageNumbers0"]),
.spg-panel__content .spg-row:has([data-key="choicesFromQuestion0"]),
.spg-panel__content .spg-row:has([data-key="showOtherItem0"]),
.spg-panel__content .spg-row:has([data-key="showClearButton0"]),
.spg-panel__content .spg-row:has([data-key="separateSpecialChoices0"]),
.spg-panel__content .spg-row:has([data-key="rateStep0"]),
.spg-panel__content .spg-row:has([data-key="showNoneItem0"]),
.spg-panel__content .spg-row:has([data-key="showSelectAllItem0"]),
.spg-panel__content .spg-row:has([data-key="choicesOrder0"]),
.spg-panel__content .spg-row:has([data-key="Image value0"]),
.spg-panel__content .spg-row:has([data-key="QuestionImage0"]),
.spg-panel__content .spg-row:has([data-key="autocomplete0"]),
.spg-panel__content .spg-row:has([data-key="dataList0"]),
.spg-panel__content .spg-row:has([data-key="name0"]),
.svc-question__content.svc-question__content--tagbox.svc-question__content--selected
  > div.sd-element.sd-question.sd-row__question.sd-row__question--small
  > div.sd-question__content {
  margin-top: 0px;
}

.p-datatable-table .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

// For image preview
.p-image-preview-container:hover > .p-image-preview-indicator {
  border-radius: 6px;
  margin: 11px;
  object-fit: cover;
  height: 93%;
}

.svc-creator__banner {
  display: none;
}

// For dialog box close button
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: 0 0 0 1px var(--colors-primary) !important;
}
