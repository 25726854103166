%transition {
  transition: 0.3s ease-in-out;
}

.toggle {
  @apply 
  w-16
  h-10
  flex
  justify-between
  items-center
  rounded-full
  p-1
  bg-silver
  cursor-pointer;
}

.active {
  @apply
  bg-pale-orange;
}

.disabled {
  @apply
  cursor-not-allowed
  hover:opacity-70;
}

.check {
  @apply
  bg-white
  h-8
  w-8
  rounded-full
  shadow-md
  transform duration-300 ease-in-out;
}

.transformation {
  @apply
  transform translate-x-6;
}

.spinner {
  @apply
  w-6
  px-1
  m-2
  rounded-full;
  @extend %transition;
}
