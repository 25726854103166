%btn {
  @apply
  p-5
  mb-6
  text-left
  rounded-md
  border
  cursor-pointer
  hover:opacity-90;
}

.default {
  @apply
  border-transparent
  bg-silver-md-light
  bg-opacity-50;
  @extend %btn;
}

.report {
  @apply
  p-3
  flex
  justify-center
  items-center
  text-sm
  text-dawn-gray
  rounded-full
  border
  cursor-pointer
  hover:opacity-90;
  min-width:50px
}

.reportSelected {
  @apply
  p-3
  flex
  bg-pampas
  justify-center
  items-center
  text-sm
  text-dawn-gray
  rounded-full
  border
  cursor-pointer
  hover:opacity-90;
  min-width:50px
}

.selected {
  @apply
  bg-primary
  bg-opacity-30
  border-primary;
  @extend %btn;
}