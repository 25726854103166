$thumbSize: 2.625rem;

.sliderInput {
  -webkit-appearance: none;
  overflow: hidden;
  height: 3.625rem;
  width: calc(100% + 0.5rem);
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-left: -0.25rem;
  background: transparent;
  position: absolute;
}

.sliderInputContainer {
  input[type=range]::-moz-range-thumb {
    border: none;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 100%;
    background: white;
    box-shadow: 0 0 0.375rem rgba(123, 123, 123, 0.25);
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 100%;
    background: white;
    box-shadow: 0 0 0.375rem rgba(123, 123, 123, 0.25);
    margin-top: -$thumbSize / 4;
    cursor: pointer;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 1.25rem;
    border-radius: 1.25rem;
    cursor: pointer;
    background: transparent;
  }

  input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    height: 1.25rem;
    border-radius: 1.25rem;
    cursor: pointer;
    background: transparent;
  }
}

.backgroundLeftLayer,
.withoutColorLeftLayer,
.whiteColorLeftLayer {
  background: linear-gradient(270.02deg, #FE5C5C 10.34%, #FEB95C 50.93%, #40B284 99.98%);position: absolute;
  position: absolute;
  border-radius: 1.25rem;
  width: 100%;
  height: 1.25rem;
}

.backgroundRightLayer {
  background: #f2f1ee;
  position: absolute;
  right: 0;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  width: 50%;
  height: 1.25rem;
}

.withoutColorLeftLayer{
  background: linear-gradient(270.02deg, #9b9b9b 10.34%, #bdbdbd 50.93%, #dbdad8 99.98%);position: absolute;
}

.whiteColorLeftLayer{
  background: linear-gradient(270.02deg, #ffffff 10.34%, #ffffff 50.93%, #ffffff 99.98%);position: absolute;
  box-shadow: 0 0 15px rgba(174, 174, 174, 0.498);
}

.whitebackgroundRightLayer{
  background: #ffffff;
  position: absolute;
  right: 0;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  width: 50%;
  height: 1.25rem;
}