%custom-popup-content {
  width: 14rem;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: -0.5rem 1rem 2.5rem rgba(24, 16, 9, 0.1);
  border: none;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.default-content {
  width: 14rem;
  @extend %custom-popup-content;
}

.navbar-content {
  width: 15rem;
  @extend %custom-popup-content;
}

.pdf-content {
  width: 15.5rem;
  @extend %custom-popup-content;
}

.csv-content {
  width: 20rem;
  @extend %custom-popup-content;
}

.accounts-content {
  width: 20rem;
  max-height: 22rem;
  overflow-y: auto;
  @extend %custom-popup-content;
}

.popup-arrow {
  display: none;
}
