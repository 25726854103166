.topicAccordion > div:last-child > div > div:last-child > div {
  background: transparent;
  border: none;
  padding-top: 0px;
}

.topicAccordion:last-child {
  border-bottom: 2px solid #e2e2e1;
}

.topicAccordion > div:first-child a {
  background: transparent !important;
  border: none !important;
  border-top: 2px solid #e2e2e1 !important;
  text-align: center;
  border-radius: 0px !important;
}

.topicAccordion div:first-child a:focus {
  box-shadow: 0 0 0 1px #fffefb !important;
}

.topicAccordion div:first-child a > span:last-child {
  width: 100%;
}
