@font-face {
  font-family: 'Poppins-Medium';
  src: url('../assets/fonts/Poppins-Medium.eot');
  src: local('Poppins Medium'), local('Poppins-Medium'),
  url('../assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Poppins-Medium.woff') format('woff'),
  url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../assets/fonts/Poppins-Regular.eot');
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('../assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Poppins-Regular.woff') format('woff'),
  url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../assets/fonts/Poppins-Light.eot');
  src: local('Poppins Light'), local('Poppins-Light'),
  url('../assets/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Poppins-Light.woff') format('woff'),
  url('../assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../assets/fonts/Poppins-Bold.eot');
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('../assets/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Poppins-Bold.woff') format('woff'),
  url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
