.with-shadow {
  box-shadow: 0 -0.125rem 0.625rem rgba(168, 168, 168, 0.1);
}

.disabledChange {
  :global {
    .react-calendar__viewContainer {
      pointer-events: none;
    }
  }
}

.custom-calendar {
  font-family: "Poppins", sans-serif;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 2px #e7e4e3, 0px 32px 40px rgba(168, 168, 168, 0.1);
  padding: 2rem 1.563rem;
  padding-top: 2.625rem;
  width: 26.625rem;

  :global {
    .react-calendar__tile {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 0.938rem;
      color: #585550;
      margin-top: 0.5rem;
      flex-basis: auto !important;

      width: 2.813rem;
      height: 2.813rem;
      border-radius: 50%;

      transition: 0.3s ease;

      &.react-calendar__tile--active {
        background: #d1c7bb;
        color: #ffffff;
      }

      &:enabled:hover,
      &:enabled:focus {
        background: #d1c7bb;
      }

      &:hover {
        background: #d1c7bb;
      }
    }

    .react-calendar__tile:disabled {
      color: #959595;
    }

    .react-calendar__navigation__arrow {
      display: flex;
      align-items: center;
      width: 2.5rem;
      padding: 0 0.5rem;

      &.react-calendar__navigation__next-button {
        justify-content: flex-start;
      }

      &.react-calendar__navigation__next-button {
        justify-content: flex-end;
      }
    }

    .react-calendar__month-view__weekdays {
      justify-content: space-between;
      border-bottom: 1px solid #eae8e7;

      &__weekday {
        display: flex;
        justify-content: center;
        flex-basis: auto !important;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 0.875rem;
        color: #585550;
        width: 2.813rem;
        height: 2rem;

        & > abbr {
          text-decoration: none;
        }
      }
    }

    .react-calendar__navigation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.25rem;

      .react-calendar__navigation__label__labelText {
        font-family: "Poppins", sans-serif;
        font-weight: 800;
        font-size: 1.125rem;
        color: #585550;
      }
    }

    .react-calendar__month-view__days {
      gap: 0.625rem;
    }

    .react-calendar__month-view__days__day--neighboringMonth > abbr {
      color: #959595;
      opacity: 0.9;
    }
  }
}
