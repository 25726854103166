.nav {
    @apply
    bg-main
    flex
    flex-col
    items-center
    justify-between;
    min-width: 9rem;
    width: 155px;
    text-align: center;
}

.viewLayout {
    width: calc(100vw - 9rem);
}

.navbarList{
    max-height: calc(100vh - 150px);
    overflow: auto;
}
