%transition {
  transition: 0.3s ease-in-out;
}

.btn {
  @apply
  flex
  hover:bg-pampas
  py-3
  px-4
  cursor-pointer
  rounded-lg
  w-full
  text-dawn-gray
  items-center;
  @extend %transition;
}
