.input {
  @apply placeholder-gray-light
  text-dawn-gray
  bg-silver-light
  rounded;

  &-field {
    @apply pl-6
    pr-12
    py-5
    w-full;
  }

  input {
    background: transparent;
  }
}

.search {
  @apply placeholder-gray-pale-light
  text-dawn-gray
  bg-silver-light
  w-full
  h-full
  rounded-lg;

  &-field {
    @apply pl-14
    pr-12
    py-5
    w-full
    h-full;
  }

  input {
    background: transparent;
  }
}

.gray {
  @apply placeholder-gray-light
  text-dawn-gray
  bg-silver-light
  rounded;

  &-field {
    @apply pl-6
    pr-12
    py-5
    w-full;
  }

  input {
    @apply opacity-60;
    background: transparent;
  }
}
